@font-face {
  font-family: 'akweprosc';
  src: url(./fonts/akweprosc-regular.otf);
}
@font-face {
  font-family: 'poppins';
  src: url(./fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: 'poppins-light';
  src: url(./fonts/Poppins-Light.ttf);
}
